@charset "UTF-8";
@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* reset */
*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: 'Pretendard';
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* *::selection { background: #555; color: white; } */
/* *::-webkit-scrollbar {
  width: 0.7rem;
  height: 8px;
  background-color: #f2f2f2;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5 );
  border-radius: 10px;
} */

/* type number 증감 버튼 삭제 */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

html {
  height: 100%;
  overflow-y: scroll !important;
  overflow-x: auto !important;
}

html,
body {
  margin: 0;
  padding: 0 !important;
  background-color: #f5f5f5 !important;
}
ol,
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}

button:disabled {
  cursor: no-drop;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}
h1 {
  font-size: 32px;
  font-weight: 700;
}
h2 {
  font-size: 24px;
  font-weight: 700;
}
h3 {
  font-size: 20px;
  font-weight: 700;
}
h4 {
  font-size: 18px;
  font-weight: 400;
}
h5 {
  font-size: 16px;
  font-weight: 400;
}
h6 {
  font-size: 14px;
  font-weight: 400;
}

/* color */
:root {
  --color-primary: #2e5cff;
  --color-primary-700: #0038ff;
  --color-primary-500: #5e81f4;
  --color-primary-400: #89a3ff;
  /* font */
  --color-font-blue: #2e5cff;
  --color-font-grey: #777777;
  --color-font-grey-300: #9a9a9a;
  /* border */
  --color-border-black: #000000;
  --color-border-grey-500: #bbbbbb;
  --color-border-grey-400: #d2d2d2;
  --color-border-grey-300: #eeeeee;
  --color-border-blue: #2e5cff;
  --color-border-emerald-300: #acd1c8;
  /* background */
  --color-background-grey: #f5f5f5;
  --color-background-dark-blue: #0e2445;
  --color-background-light-blue: #f2f3f5;
  --color-background-blue: #2e5cff;
  --color-background-blue-300: #dfe6fd;
  --color-background-green: #1a735e;
  --color-background-emerald: #5fcdb3;
  --color-background-yellow: #ffd81c;
  /* button */
  --color-button-quinary: #d0daff;
  --color-button-yellow: #ffd81c;
  /* attendance */
  --color-attendance: #037e00;
  --color-attendance-absent: #ff0000;
  --color-attendance-tardy: #ffa858;
}

/* 승급서 프린트 스타일링 */
@media print {
  div.levelup_ref {
    background-image: url('https://tk-world.s3.ap-northeast-2.amazonaws.com/license/License.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 100%;
    height: 100%;
  }
  div.levelup_ref div#levelup_profile {
    margin-top: 80px;
    margin-left: 35px;
  }
  div.levelup_ref div#levelup_info {
    padding-top: 120px;
    margin-left: 80px;
  }
  div.levelup_ref div#levelup_rank {
    margin-top: 65px;
    margin-left: 92px;
  }
  div.levelup_ref div#levelup_date {
    position: absolute;
    bottom: 250px;
  }
}

@page {
  size: A4;
  margin: 0;
}
