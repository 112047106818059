.bg-body {
  background-color: #f5f5f5;
}

.main-wrap {
  min-width: 1903px;
  width: 100%;
  height: 100vh;
  /* display: flex; */
  align-items: start;
  justify-content: space-between;
}

/* header.jsx */
.header-container {
  /* min-width: 1903px; */
  width: 100%;
  height: 70px;
  background-color: #fff;
  color: #000;
  line-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  border-bottom: 1px solid var(--color-border-grey-400);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  z-index: 99;
}
.header-container .header-logo {
  display: flex;
  align-items: center;
  padding-left: 35px;
  /* justify-content: center; */
  min-width: 233px;
  margin-top: 5px;
}

.header-container .header-logo a {
  font-size: 30px;
  line-height: 40px;
}
.header-container .header-nav {
  height: 100%;
  margin-left: 40%;
  display: flex;
}
.header-container .header-nav div {
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;
}
.header-container .header-nav div:hover {
  border-bottom: 3px solid #2f5df2;
}
.header-container .header-right {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.header-container .header-right img {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

/* sidemenu.jsx */
.side-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 99;
  min-height: 100vh;
  height: calc(100vh - 50px);
  min-width: 233px;
  width: 233px;
  background-color: #fff;
  padding: 0px 0px;
  top: 0;
  border-right: 1px solid var(--color-border-grey-400);
}
.side-container .side-profile {
  height: 50px;
  background-color: #fff;
  width: 233px;
  border-right: 1px solid var(--color-border-grey-400);
}
.side-profile-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 9px;
  margin-bottom: 5px;
}
.side-profile-img p {
  font-weight: 700;
  font-size: 17px;
  margin: 0;
}
.side-profile-btn {
  margin-top: 10px;
}
.side-profile-btn button:hover {
  background-color: #5e81f4;
  border: 1px solid #5e81f4;
}
.side-profile-img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.side-container .ASside-profile {
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
.side-container .ASside-profile-img img {
  width: min-content;
  margin: 0 auto;
}
.side-container .side-menu {
  /* padding: 60px 0; */
}
.side-container .side-menu div {
  padding-left: 100px;
  position: relative;
  cursor: pointer;
  height: 40px;
}
.side-container .side-menu div:hover {
  border-right: 3px solid #2f5df2;
}
.side-container .side-menu div:hover span {
  color: #000;
}
.side-container .side-menu div span {
  display: block;
  white-space: nowrap;
  color: #8da1b5;
  font-weight: 700;
}
.side-container .side-menu div span:first-child {
  width: 40px;
  height: 100%;
  position: absolute;
  left: 40px;
  top: 1px;
  background-repeat: no-repeat;
  background-position: center;
}
.side-container .side-menu div span:last-child {
  text-align: left;
  padding: 10px 0;
}

/* main.jsx */
.main-container {
  min-width: 1670px;
  width: calc(100% - 233px);
  min-height: calc(100vh - 69px);
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
}
.main-contents {
  /* min-width: 1560px; */
  /* min-width: 1610px;
  width: 100%; */
  min-height: 100%;
  padding: 20px 30px;
  position: relative;
  /* z-index: 1; */
  background: #f5f5f5;
}
.main-contents-noPadding {
  /* min-width: 1560px; */
  /* min-width: 1610px;
  width: 100%; */
  min-height: 100%;
  /* padding: 30px; */
  position: relative;
  /* z-index: 1; */
  background: #f5f5f5;
}

/* article.jsx */
.article-container {
  min-width: 198px;
  height: 370px;
  margin: 15px 20px 0 0;
  background-color: #fff;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
  /* position: sticky; */
  top: 55px;
  padding: 10px;
}
.article-imgbox {
  width: 100%;
  height: 120px;
  border-radius: 10px;
  background: #f5f7f8;
}

/* form */

.form-box {
  width: 100%;
  max-width: 900px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .form-box > form {
    width: 90% !important;
    padding: 0;
  }
}

/* @media screen and (min-width: 845px) {
  .res_container{
    width: 500px;
  }
}
@media screen and (min-width: 1220px) {
  .res_container{
    width: 900px;
  }
}
@media screen and (min-width: 1650px) {
  .res_container{
    width: 1320px;
  }
} */

.type_selected {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  position: relative;
}
.type_selected::before {
  content: '';
  background-image: url('https://tk-world.s3.ap-northeast-2.amazonaws.com/asset/images/icon/check/check_lg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: -65px;
  left: calc(50% - 40px);
  width: 100px;
  height: 100px;
}

input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}

.MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 0;
}

/* pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #babfc7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-left: -1px;
}

ul.pagination li:first-child {
  border-radius: 0;
}

ul.pagination li:last-child {
  border-radius: 0;
}

ul.pagination li a {
  text-decoration: none;
  color: #000000;
  font-size: 1rem;
}

ul.pagination li.active a {
  color: #000000;
  font-weight: 400;
}

ul.pagination li.active {
  background-color: #e9e9e9;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  /* color: rgb(26, 26, 59); */
  font-weight: 700;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

/* print - certificate */
.levelup_kr {
  font-size: 14px;
  position: absolute;
  top: 313px;
  left: 200px;
}

.levelup_nation {
  font-size: 14px;
  position: absolute;
  top: 359px;
  left: 200px;
}

.levelup_name {
  font-size: 14px;
  position: absolute;
  top: 403px;
  left: 200px;
}

.levelup_birth {
  font-size: 14px;
  position: absolute;
  top: 448px;
  left: 200px;
}

.levelup_dojang_name {
  font-size: 14px;
  position: absolute;
  top: 491px;
  left: 200px;
  width: 380px;
}

.levelup_middle {
  position: absolute;
  bottom: 200px;
  left: 105px;
}

.levelup_date {
  position: absolute;
  bottom: 150px;
  left: 270px;
}

.levelup_bottom_left {
  bottom: 80px;
  left: 100px;
  width: 135px;
  height: 54px;
}

.levelup_bottom_middle_dojang {
  bottom: 76px;
  left: 235px;
  width: 280px;
  min-height: 60px;
}

.levelup_bottom_middle_master {
  bottom: 45px;
  left: 235px;
}

.levelup_right {
  bottom: 45px;
  left: 520px;
}

.levelup_right_length {
  bottom: 43px;
  left: 490px;
}

@media print {
  .levelup_kr {
    font-size: 16px;
    position: absolute;
    top: 397px;
    left: 250px;
  }
  .levelup_nation {
    font-size: 16px;
    position: absolute;
    top: 454px;
    left: 250px;
  }
  .levelup_name {
    font-size: 16px;
    position: absolute;
    top: 510px;
    left: 250px;
  }
  .levelup_birth {
    font-size: 16px;
    position: absolute;
    top: 566px;
    left: 250px;
  }
  .levelup_dojang_name {
    font-size: 16px;
    position: absolute;
    top: 621px;
    left: 250px;
  }
  .levelup_middle {
    position: absolute;
    bottom: 270px;
    left: 130px;
  }
  .levelup_date {
    position: absolute;
    bottom: 200px !important;
    left: 350px;
  }
  .levelup_bottom_left {
    bottom: 100px;
    left: 130px;
    width: 170px;
  }
  .levelup_bottom_middle_dojang {
    bottom: 98px;
    left: 290px;
  }
  .levelup_bottom_middle_master {
    bottom: 55px;
    left: 290px;
  }
  .levelup_right {
    bottom: 65px;
    left: 590px;
  }
  .levelup_right_length {
    bottom: 65px;
    left: 590px;
  }
}

/* print - admin bodix application */
.bodix_form_container {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.bodix_form_terms {
  margin: 10px 10px 10px 122px;
}

@media print {
  .bodix_form_container {
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
  }

  .bodix_form_terms {
    margin: 10px 10px 10px 130px;
  }
}

/* print - healthcare reports */
@media print {
  .healthcare_container {
    width: 100%;
    height: 100%;
  }

  .healthcare_reports {
    object-fit: cover;
  }
}

/* No-Scrollbar */
.no-scrollbar {
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

/* Scrollbar - SideMenu*/
.side_menu_scroll {
  margin-top: 168px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.side_menu_scroll::-webkit-scrollbar {
  width: 6px;
}

.side_menu_scroll::-webkit-scrollbar-track {
  margin-top: 5px;
  margin-bottom: 120px;
}

.side_menu_scroll::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 100px;
}

.side_menu_scroll::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

/* Scrollbar - DataGridMini*/
.grid_scroll::-webkit-scrollbar {
  width: 6px;
}

.grid_scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.grid_scroll::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 50px;
}

.grid_scroll::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}

/* homepage */

/* login */
.loginWrap {
  width: 30vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px 0px 0px 0px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
.loginWrap::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
}

@media screen and (max-width: 1280px) {
  .loginWrap {
    display: none;
  }
}
